@import "ri-global.scss";@import "nielsen-global.scss";
.rrm-content.m-medium {
  width: 400px !important;
}

.modal-confirm {

}

.react-redux-modal .rrm-body {
  padding: 18px !important;
}

.modal-close {
  color: white;
  opacity: 0.6;
}

.modal-close:hover {
  color: white;
  opacity: 1;
}
.modal-error p {
  padding-top: 5px;
  font-size: 14px !important;
}

.reports-manager-page .reports-table .ReactVirtualized__Table__rowColumn {
  white-space: normal;
}

.reports-manager-action-column span.actionButton {
  color: $primary-color;
  font-weight: bold;
  cursor: pointer;
  padding-right: 12px;
  font-size: 14px;
  line-height: 1.5em;
  float: left;
}
