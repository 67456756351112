@import "ri-global.scss";@import "nielsen-global.scss";
#publicKey td input {
  min-width: 200px;
  border-radius: 5px;
  height: 30px;
}

.groupEditForm tr td:first-child,
.feedSelectContainer .FormSelect__container label,
.feedSelectContainer label {
  width: 180px;
}

.feedSelectContainer .FormSelect__field,
.feedSelectContainer .toggleContainer {
  padding: 5px;
}

.feedSelectContainer .FormSelect__field .Select {
  width: 200px;
}

.feed-advanced-header {
  font-size: 18px;
}

.input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ccc;
  font-size: 11px;
}
.input::-moz-placeholder { /* Firefox 19+ */
  color: #ccc;
  font-size: 11px;
}
.input:-ms-input-placeholder { /* IE 10+ */
  color: #ccc;
  font-size: 11px;
}
.input:-moz-placeholder { /* Firefox 18- */
  color: #ccc;
  font-size: 11px;
}

.group-note {
  font-size: 14px;
  font-style: italic;
}

.font-14 {
  font-size: 14px;
}

#ftps-password-format-tooltip .tooltip-inner{
  text-align: left;
  font-size: 14px;
}
