@import "ri-global.scss";@import "nielsen-global.scss";
.app .header {
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 9999;
  padding-right: 18px;
  border-bottom: 1px solid #dedede;
}

.extendedHeader {
  margin-left: 2%;
  width: 100%;
}

.logo {
  height: 40px;
}

.app .header .userDropDownButton {
  margin-left: 20px;
  font-size: 14px;
  cursor: pointer;
}

.app .header .homeLink {
  color: $dark-grey;
  margin-left: 25px;
  text-transform: uppercase;
  font-size: 14px;
}

.app .header .containerMenu {
  position: absolute;
  background: white;
  z-index: 1060;
  right: 12px;
  box-shadow: 0 5px 10px rgba(black, 0.2);
  display: block;
  border: 1px solid $primary-color;
  width: auto;
  min-width: 150px;
  max-width: 180px;
}

.arrow,
.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.arrow {
  border-width: 10px;
}

.arrow:after {
  content: '';
  border-width: 10px;
}

.dropDownButton--header {
  color: $grey;
}

.dropDownButton--header:hover {
  color: $dark-grey;
  fill: $dark-grey;
}

.header .active {
  color: $dark-grey;
  fill: $dark-grey;
}

.dropDownButton--header:hover #ProfileIcon {
  fill: $dark-grey;
}

.active #ProfileIcon {
  fill: $dark-grey;
}

/* Bottom
-------------------------------------------------- */

.bottom > .arrow {
  top: -10px;
  border-top-width: 0;
  border-bottom-color: rgba(black, 0.25);
  margin-left: -10px;
}

.bottom > .arrow:after {
  top: 1px;
  content: ' ';
  border-top-width: 0;
  border-bottom-color: white;
  margin-left: -10px;
}

/* Top
-------------------------------------------------- */

.top > .arrow {
  bottom: -10px;
  border-bottom-width: 0;
  border-top-color: rgba(black, 0.25);
  margin-left: -10px;
}

.top > .arrow:after {
  bottom: 1px;
  content: ' ';
  border-bottom-width: 0;
  border-top-color: white;
  margin-left: -10px;
}

.center > .arrow {
  left: 50%;
}

.end > .arrow {
  right: 10px;
}
