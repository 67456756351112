@import "ri-global.scss";@import "nielsen-global.scss";
// Rewrite default modal's styles //

// in case when we use custom footer inside body
.modal-body-footer {
  display: flex;
  justify-content: center;

  margin-top: 20px;

  .btn {
    margin: 0 15px;
  }
}
