// ### Custom font classes

// This style create easily, for example
// fs-16-20-b or fs-16-20 mean =>
// {
//   font-size: 16px;
//   line-height: 20px;
//   font-weight: 700, (only for this class fs-16-20-b)
// }

@mixin f-size($size, $height, $bold: false) {
  font-size: (1px * $size);
  line-height: (1px * $height);
  @if $bold {
    font-weight: 700;
  }
}
// For create new font style add font-size & line-height to '$fontStyle' under (example 12 16,)
$fontStyle: 12 16, 14 16, 16 20;

@each $f-size, $l-height in $fontStyle {
  .fs-#{$f-size}-#{$l-height}-b,
  .fs-#{$f-size}-#{$l-height} {
    @include f-size($f-size, $l-height);
  }
  .fs-#{$f-size}-#{$l-height}-b {
    font-weight: 700;
  }
}
