$yellow-color: #fab34d;
$red-color: #ee6b50;
$red-dark-color: #a94442;
$blue-color: #276fbf;
$green-color: #3c763d;
$grey-color: #606060;

/* Color Palette */
$primary-color: #32aec0;
$secondary-color: #ee6b50;
$light-primary-color: #66e2da;
$dark-primary-color: #1e6873;
$tertiary-color: $yellow-color;
$font-color: $grey-color;

$primary-color-opacity_1: rgba(50, 174, 192, 0.1);
$primary-color-opacity_2: rgba(50, 174, 192, 0.2);

/* 50 Shades of Grey */
$background-grey: #f7f7f7;
$super-light-grey: #f5f5f5;
$light-grey: #dedede;
$middle-grey: #ccc;
$grey: #999999;
$dark-grey: #4c4c4c;

/* buttons */
$button-background-color: $primary-color;
$button-color-hover: #ffffff;
$button-secondary-color: $primary-color;
$button-secondary-background-hover: #ffffff;
