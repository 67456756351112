@import "ri-global.scss";@import "nielsen-global.scss";
.list-style {
  width: 200px !important;
  list-style-type: none !important;
}

.wrapper {
    position: static;
    margin-bottom: 30px;
    overflow:hidden;
    margin-bottom: 5px;
}

.list {
  left: 0px;
  top: 0px;
  margin-top: 0px;
}

.scroller {
  position: relative;
  z-index: 10;
  text-align:center;
  cursor:pointer;
  padding:7px;
  padding-top:11px;
  white-space:no-wrap;
  vertical-align:middle;
  background-color: $background-grey;
}

.scroller-right{
  float:right;
  margin-right: -5px;
  margin-top: -49px;
}

.scroller-left {
  float:left;
  margin-left: -5px;
  border: 1px solid $background-grey;
  margin-top: -50px;
}

.glyphi {
  opacity: 0.60;
}
.glyphi:hover {
  opacity: 1;
}

.drag-icon {
  margin-bottom: 2px;
  margin-right: 4px;
  cursor: move;
}

.tablistContainer {
  position: static;
  margin-bottom: 30px;
  width: 100%;
}

.no-wrap-container {
  height: 32px;
  white-space: nowrap;
  overflow: hidden;
}
