@import "ri-global.scss";@import "nielsen-global.scss";
.modal {
  z-index: 10001;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 15px;
}

.modal.form-modal {
  justify-content: normal;
}

.modal-backdrop {
  z-index: 10000;
}

.modal-content {
  border-radius: 0;
  padding: 30px;
  border: none;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 12px;
}

.modal-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}

.modal-content {
  background-color: white;
  color: $dark-grey;
}

.error-modal > .modal-content {
  background-color: $secondary-color;
  color: white;
}

.confirm-modal > .modal-content {
  background-color: $tertiary-color;
  color: white;
}

.success-modal > .modal-content {
  background-color: $background-grey;
}

.modal-body {
  font-size: 14px;
  line-height: 18px;
  padding: 0;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-footer {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  padding: 0;
}

.modal-buttons {
  display: flex;
}

.modal-buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  color: $dark-grey;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 12px;
  margin: 0 18px;
  border-radius: 0;
}
.form-modal .modal-buttons button:hover,
.info-modal .modal-buttons button:hover {
  box-shadow: 0px 4px 8px 4px $light-grey;
}

.error-modal .modal-buttons button,
.success-modal .modal-buttons button,
.confirm-modal .modal-buttons button {
  border: none;
}

div.m-large {
  min-width: 1000px;
}

.FilterListModal__textarea {
  width: 100%;
  height: 100px;
  resize: none;
  outline: none;
  color: black;
}

.react-redux-modal .rrm-holder .scroll .rrm-content.m-large {
  overflow: visible;
}

.form-modal .btn-ok,
.info-modal .btn-ok {
  border: 1px solid $primary-color;
  background-color: $primary-color;
  color: white;
  font-weight: bold;
}

.form-modal .btn-cancel,
.info-modal .btn-cancel {
  background-color: white;
  border: 1px solid $grey;
  font-weight: bold;
}

.info-modal .btn[disabled]:hover,
.form-modal .btn[disabled]:hover,
.info-modal .btn[disabled],
.form-modal .btn[disabled] {
  background-color: $light-grey;
  border-color: $light-grey;
  color: white;
}

.btn-delete {
  background-color: #f06647;
  border: 1px solid $grey;
}
.btn-delete:hover {
  background-color: #f18a73;
}

.form-modal .modal-header {
  justify-content: flex-start;
}

.form-modal .modal-content {
  text-align: left;
}

.form-modal .modal-footer {
  justify-content: flex-end;
}
