@import "ri-global.scss";@import "nielsen-global.scss";

.resetPasswordPage .authBox h1, .resetPasswordPage .authBox h4 {
  color: white;
  min-width: 80%;
  margin: 0 auto;
  padding-bottom: 40px;
  text-transform: uppercase;
  font-family: 'Roboto', "Helvetica Neue", Arial, sans-serif;
}

#refresh-load-password-format .tooltip-inner{
  text-align: left;
  font-size: 10px;
}

.resetPasswordPage .glyphicon-info-sign {
  color: white;
}
