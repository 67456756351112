$primary-color: #2d6df6;
$secondary-color: #ef5f17;
$dark-primary-color: #2458c4;
$light-primary-color: #659eff;

$primary-color-opacity_1: rgba(45, 109, 246, 0.1);
$primary-color-opacity_2: rgba(45, 109, 246, 0.2);

/* buttons */
$button-background-color: $primary-color;
$button-color-hover: #ffffff;
$button-secondary-color: $primary-color;
$button-secondary-background-hover: #ffffff;
