.css-1pahdxg-control {
  border-color: $primary-color !important;
  box-shadow: 0 0 0 1px $primary-color !important;
}

.validation-error .css-1s2u09g-control {
  border: solid 1px $red-dark-color !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border-color: $primary-color !important;
}

.css-1n7v3ny-option {
  background-color: $super-light-grey !important;
}

.css-9gakcf-option {
  background-color: $primary-color !important;
}
