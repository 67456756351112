@import "ri-global.scss";@import "nielsen-global.scss";
.custom-report-buttons {
  color: white;
  background-color: $button-background-color;
  border: none !important;
  text-transform: capitalize;
  font-size: 14px !important;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 6px;
  padding: 6px 12px;
  font-weight: bold;
  height: 30px;
}

.custom-report-buttons:hover {
  color: $button-color-hover;
  background-color: $primary-color;
}

.custom-report-buttons:disabled {
  box-shadow: 0px 0px 0px 0px;
  background-color: $light-grey;
}

.custom-report-buttons:disabled:hover {
  color: $grey;
  cursor: not-allowed;
}
