@import "ri-global.scss";@import "nielsen-global.scss";
.flatMenuList {
  font-size: 14px;
  padding-left: 0px;
  margin-bottom: 16px;
  line-height: 30px;
  border-bottom: 1px solid $light-grey;
  min-width: 340px;
  transition: margin ease-in-out 0.3s;
}

.flatMenuList .filterPhrase {
  padding-bottom: 18px;
}

.flatMenuList li {
  display: inline;
  list-style-type: none;
  padding-right: 24px;
  font-size: 14px;
}

.flatMenuList li:last-child {
  padding-right: 0px;
}
.flatMenuList .selectedMenuItem > span,
.flatMenuList .selectedMenuItem > a {
  font-weight: bold;
  color: $dark-grey;
  border-bottom: 4px solid $primary-color;
  padding-bottom: 5px;
  cursor: pointer;
}

.flatMenuList > li > span,
.flatMenuList > li > a {
  color: $grey;
  cursor: pointer;
}


.flatMenuList > li > span:hover,
.flatMenuList > li > a:hover {
  text-decoration: none;
  color: $dark-grey;
  border-bottom: 4px solid $primary-color;
  padding-bottom: 5px;
}
