@import "ri-global.scss";@import "nielsen-global.scss";
.Toggle {
  height: 35px;
  line-height: 35px;
  background-color: $middle-grey;
  color: $grey;
  font-size: 16px;
  border-radius: 5px;
  position: relative;
  border: none;
  outline: none;
  width: 130px;
  padding-left: 65px;
  transition: padding .2s;
  cursor: pointer;
}

.Toggle.active {
  background-color: $secondary-color;
  color: white;
  padding-left: 20px;
}

.Toggle > .Toggle-inner-button {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
  background-color: white;
  border-radius: 5px;
  transform: translateX(-95px);
  transition: transform .2s;
}

.Toggle.active > .Toggle-inner-button {
  transform: translateX(0);
}

.Toggle[disabled] {
  color: $grey;
  background-color: $light-grey;
  cursor: not-allowed;
}
