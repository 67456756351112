@import "ri-global.scss";@import "nielsen-global.scss";
.ctm-report-edit-page {
  width: 80%;
  margin: 48px auto;
}

.report-action-row {
  padding-bottom: 20px;
}

.ctm-report-edit-page input[type='text'].form-control {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0px;
  box-shadow: none;
}

.ctm-report-edit-page input[type='text'].form-control.unedited {
  background-color: #EDEDED;
}

.ctm-report-edit-page .control-label {
  height: 34px;
}

.ctm-report-edit-page input[type='text'].form-control[readonly] {
  background-color: #eeeeee;
}

.ctm-report-edit-page .has-error .cm-sql-query-sf-input,
.ctm-report-edit-page .has-error input[type='text'].form-control {
  border-color: $red-color;
}

.ctm-report-edit-page .has-error .cm-sql-query-sf-input {
  box-shadow: inset 0 1px 1px rgba(black,.075), 0 0 6px $red-color;
}

.ctm-report-edit-page .has-error .checkbox,
.ctm-report-edit-page .has-error .checkbox-inline,
.ctm-report-edit-page .has-error .control-label,
.ctm-report-edit-page .has-error .form-control-feedback,
.ctm-report-edit-page .has-error .help-block,
.ctm-report-edit-page .has-error .radio,
.ctm-report-edit-page .has-error .radio-inline,
.ctm-report-edit-page .has-error.checkbox label,
.ctm-report-edit-page .has-error.checkbox-inline label,
.ctm-report-edit-page .has-error.radio label,
.ctm-report-edit-page .has-error.radio-inline label {
  color: $red-color;
}

.ctm-report-edit-page .has-warning input[type='text'].form-control {
  border-color: #8a6d3b;
}

.ctm-report-edit-page .has-success input[type='text'].form-control {
  border-color: #3c763d;
}

.ctm-report-edit-page .report-form-header .report-id {
  text-transform: uppercase;
  font-size: 12px;
  color: $grey;
}

.ctm-report-edit-page .report-form-header .report-name {
  font-size: 24px;
  font-weight: 300;
}

.ctm-report-edit-page form.form-horizontal {
  padding: 10px;
}

.ctm-report-edit-page form.form-horizontal.dirty h4{
  font-style: italic;
}
.ctm-report-edit-page form.form-horizontal.dirty h4::after {
  content: '*';
  font-weight: bold;
}

.folder-selector > .select-folder {
  width: 150px;
  display: inline-block;
}
.folder-selector > .root-folder {
  display: inline-block;
  top: -10px;
  font-size: 20px;
  position: relative;
}
.folder-selector .Select.is-focused > .Select-control,
.folder-selector .Select.is-focused:not(.is-open) > .Select-control {
  border-color: #aaaaaa;
  box-shadow: none;
}

.folder-selector > .select-folder .Select-control {
  border-radius: 0px;
  outline: none;
  box-shadow: none;
}

.folder-selector > .select-folder .Select-control .Select-menu-outer * {
  border-radius: 0px;
  outline: none;
  box-shadow: none;
}

.folder-selector > .select-folder .Select-control .Select-value {
  outline: none;
  padding-right: 20px !important;
}

.folder-selector .bread-crumb-separator {
  margin: 12px 3px;
}

#limit-info-tooltip .tooltip-inner {
  max-width: 350px;
  text-align: left;
  white-space: pre-line;
  font-size: 12px;
}

button.cre-sql-query-get-values,
button[disabled].cre-sql-query-get-values {
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  margin-top: 5px;
  font-size: 12px;
  height: 30px;
  border-width: 1px;
}

button.update-filter-btn,
button[disabled].update-filter-btn {
  font-size: 12px;
  height: 30px;
  border-width: 1px;
}

.cre-sql-query-field-row .glyphicon-info-sign {
  padding: 6px;
}

.cre-sql-query-field {
  font-size: 14px;
  margin-bottom: 10px;
  padding-right: 10px;
}
.cre-sql-query-field .cm-sql-query-sf-input {
  border-radius: 4px;
  border: 1px solid #ccc;
  border-top: 0px;
  color: #333;
}

.cre-sql-query-field .cm-sql-query-sf-input .CodeMirror {
  border-radius: 4px;
}

.cre-sql-query-field:focus {
  color: black;
}

.cre-sql-query-field::placeholder {
  color: #aaaaaa;
}

.cre-sql-query-field .query-info {
  padding: 6px;
  float: right;
  top: -26px;
  z-index: 10;
  position: relative;
  right: 0px;
}
.cre-sql-query-field .query-footer {
  clear: both;
  top: -26px;
  position: relative;
  font-size: 12px;
}

.filter-value-error,
.filter-values-title {
  font-size: 14px;
  line-height: 24px;
}
.filter-values-list {
  padding-left: 6px;
}

.filter-values-list .row {
  padding-top: 6px;
}

.filter-values-list .row div {
  height: 34px;
  line-height: 34px;
  text-align: center;
}

.filter-values-list .sort-handle {
  visibility: hidden;
  margin-left: -20px;
  padding-right: 10px;
  color: $grey;
}
.filter-values-list.sortable .form-group:hover .sort-handle,
.filter-values-list.sortable .row:hover .sort-handle {
  visibility: visible;
}

.ctm-report-edit-page .no-value-msg {
  text-align: center;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
}

.cre-panel-heading {
  color: #333;
  background-color: #e7e7e7;
  border: 1px solid #ddd;
  padding: 10px 15px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.cre-panel-body {
  border: 1px solid #ddd;
  border-top: 0px solid transparent;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 18px 18px;
}

.filter-values-panel {
  background: white;
  color: $dark-grey;
}

.filter-values-panel .help-block {
  margin-bottom: 0px;
}

.filter-values-header {
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 6px;
  color: $grey;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 14px;
  font-size: 12px;
}

.filter-values-action-header {
  padding-bottom: 12px;
}

.filter-values-panel #CheckBox-checkMark {
  margin: auto;
}

.table-filter {
  padding: 20px 0px;
}

.removeButton.value-action {
  cursor: pointer;
  color: $dark-grey;
}
.removeButton.value-action:hover {
  color: #ED6A50;
}

.cre-tab-filters .unselected li,
.cre-tab-filters .selected li,
li.cre-tab-filter-sort-edit {
  display: inline-block;
  margin: 0px 12px 12px 0px;
  background: white;
  border: 1px solid $primary-color;
  padding: 6px;
  cursor: pointer;
}

.cre-tab-filters .unselected li {
  border-color: #ccc;
  background-color: #eeeeee;
}

.single-select .dropdown-menu .create,
.single-select .dropdown-menu .update,
.tablistContainer .create.menu-item a,
.tablistContainer .update.menu-item a,
.single-select.create button.single-select-btn,
.single-select.update button.single-select-btn {
  font-style: italic
}
.cre-tab-filters .unselected li .subtext,
.cre-tab-filters .selected li .subtext,
li.cre-tab-filter-sort-edit .subtext,
.single-select .dropdown-menu .subtext {
  font-size: 0.8em;
  color: $grey;
}

.report-action-row {
  vertical-align: middle;
}

.report-action-row .glyphicon {
  margin-left: -12px;
}

.filter-values-errors-count {
  display: flex;
  align-items: center;
  height: 35px;
}
