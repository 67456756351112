@import "ri-global.scss";@import "nielsen-global.scss";
.beta-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 72px;
  padding: 18px;
  margin-bottom: 30px;

  border: 1px solid $primary-color;
  background-color: $primary-color-opacity_1;

  font-size: 14px;
  color: $dark-grey;
}

.beta-alert-dismiss {
  opacity: 0.6;
}

.beta-alert-dismiss:hover {
  cursor: pointer;
  opacity: 1.0;
}

.beta-alert p {
  display: flex;
  align-items: center;

  height: 100%;
  width: 100%;
  margin: 18px;
}
