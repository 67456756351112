.react-bs-table .react-bs-container-header > table > thead > tr > th .date-filter-comparator {
  width: 36px !important;
  float: left;
  padding: 6px 0px !important;
}

.react-bs-table .react-bs-container-header > table > thead > tr > th .date-filter-input {
  margin-left: 3px !important;
  float: left;
  width: 100px !important;
  padding: 6px 1px !important;
}
