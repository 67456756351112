// Like bootstrap 5 styles

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-start {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: right !important;
}
