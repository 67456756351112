@import "ri-global.scss";@import "nielsen-global.scss";
.sort-icon .sort-by {
	padding-right: 20px;
	position: relative;
}
.sort-icon .sort-by:before,
.sort-icon .sort-by:after {
	border: 5px solid transparent;
	content: "";
	display: block;
	height: 0;
	right: 5px;
	top: 50%;
	position: absolute;
	width: 0;
	transform: scaleX(0.8)
}
.sort-icon .sort-by:before {
	border-bottom-color: #cccccc;
	margin-top: -13px;
}
.sort-icon .sort-by:after {
	border-top-color: #cccccc;
	margin-top: 0px;
}

.sort-icon .sort-by.asc:before {
	border-bottom-color: $dark-grey;
}
.sort-icon .sort-by.desc:after {
	border-top-color: $dark-grey;
}
