@import "ri-global.scss";@import "nielsen-global.scss";
.CheckBox {
  height: 16px;
  border-radius: 3px;
  cursor: pointer;
 }
 .CheckBox .label {
    display: inline-block;
    margin-left: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $dark-grey;
    font-size: 12px;
    cursor: pointer;
  }
.CheckBox.container {
    padding: 9px 0;
  }
.CheckBox .checkmark {
    border: 1px solid rgb(138, 138, 138);
    width: 16px;
    height: 16px;
    min-width: 16px;
    border-radius: 3px;
    cursor: pointer;
  }
.CheckBox .checkmarkNoRadius {
    border: 1px solid rgb(138, 138, 138);
    width: 16px;
    height: 16px;
    min-width: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

.CheckBox:hover #CheckBox-checkMark {
    border-color: $primary-color;
    border-width: 1px;
  }

.CheckBox .checked {
    border: 1px solid $primary-color;
    fill: $primary-color;
  }

.CheckBox .label .toolTipProduct {
  visibility: hidden;
  background-color: $dark-grey;
  color: white;
  text-align: center;
  padding: 10px;
  /*border-radius: 6px;*/
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.CheckBox .label:hover .toolTipProduct {
  visibility: visible;
}
