@import "ri-global.scss";@import "nielsen-global.scss";
.error-boundary {
  width: 60%;
  min-width: 750px;
  margin: 10px auto;
  padding: 5px 20px;
  color: #4c4c4c;
  background-color: #f7f7f7;
  border-radius: 20px;
}

.error-boundary h2 {
  font-weight: normal;
}

.error-boundary-icon {
  background-image: url('../../assets/svgs/error-500-cone.svg');
  background-repeat: no-repeat;
  height: 200px;
}
