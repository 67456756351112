@import "ri-global.scss";@import "nielsen-global.scss";
.FormSelect__container {
  margin-bottom: 10px;
}

.FormSelect__container label {
  width: 130px;
}

.FormSelect__field {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
}
