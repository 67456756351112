@import "ri-global.scss";@import "nielsen-global.scss";
.beacon-modal {
  display: flex;
  justify-items: center;
  align-content: center;
  flex-direction: column;
  position: absolute;
  background: $primary-color;
  z-index: 10000;
  transition: top 100000ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  overflow: hidden;
  box-sizing: border-box;
  background-image: linear-gradient(145deg, $primary-color, #226daa);
  box-shadow: 0 16px 24px 0 rgba($dark-grey, 0.2);
}

.beacon-modal-title {
  width: 90%;
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 20px 15px;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
}

.beacon-modal-content {
  color: white;
  max-width: 100%;
  font-size: 16px;
  line-height: 24px;
  margin: 5px 15px 5px 15px;
}

.beacon-modal-button {
  width: 102px;
  height: 42px;
  border: solid 1px white;
  background: none;
  color: white;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 15px;
  align-self: flex-end;
}

.beacon-modal-button:hover {
  background: white;
  color: $primary-color;
}

.arrow-left {
  position: absolute;
  top: 190px;
  left: 575px;
  margin-top: -5px;
  content: '';
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 8px solid $primary-color;
  z-index: 1992;
}

.arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid $primary-color;  /* left arrow slant */
  border-top: 6px solid transparent; /* right arrow slant */
  border-bottom: 6px solid transparent; /* bottom, add background color here */
  font-size: 0;
  line-height: 0;
  left: -5px;
  top: 16px;
}

.arrow-top {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;  /* left arrow slant */
  border-right: 6px solid transparent; /* right arrow slant */
  border-bottom: 8px solid $primary-color; /* bottom, add background color here */
  font-size: 0;
  line-height: 0;
  left: 19px;
  top: 197px;
  z-index: 10001;
}

.arrow-bottom {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;  /* left arrow slant */
  border-right: 6px solid transparent; /* right arrow slant */
  border-top: 8px solid $primary-color; /* bottom, add background color here */
  font-size: 0;
  line-height: 0;
  left: 19px;
  top: 197px;
  z-index: 1003;
}

.left-nav-down {
  position: fixed;
}

.action-title {
  text-align: left;
  margin-left: 22px;
}

.periscope-filters-left {
  top: 209px;
  left: 572px;
}

.periscope-actions-right {
  top: 334px;
  left: 1351px;
  color: rgb(66, 131, 174);
}

.tableContent {
  font-size: 16px;
  line-height: 1.33;
  text-align: left;
  color: white;
  width: 80%;
  margin-left:22px;
  margin-right: auto;
  margin-bottom: 20px;
}

.tableChartDiv {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.tableChartImg {
  width: 100%;
}

.chartContent {
  font-size: 16px;
  line-height: 1.33;
  text-align: left;
  color: white;
  width: 80%;
  margin-left:22px;
  margin-right: auto;
  margin-bottom: 20px;
}

.chartPill {
  color: white;
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  width: 30%;
  text-align: center;
  background-color: $primary-color;
  border-radius: 16px;
  margin-bottom: 10px;
  margin-left: 8%;
}

.tablePill {
  color: white;
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  width: 30%;
  text-align: center;
  background-color: $primary-color;
  border-radius: 16px;
  margin-bottom: 10px;
  margin-left: 8%;
}
