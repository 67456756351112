@import "ri-global.scss";@import "nielsen-global.scss";
.pagination-wrapper {
  margin-top: 12px;
}

.pagination-wrapper .page-number {
  width: 25px;
  text-align: center;
  padding: 0px !important;
  border-bottom: 1px solid $grey;
  font-weight: bold;
  color: $dark-grey;
}

.pagination-wrapper .prev,
.pagination-wrapper .next {
  cursor: pointer;
}

.pagination-wrapper .prev {
  margin-right: 20px;
}

.pagination-wrapper .next {
  margin-left: 20px;
}

.pagination-wrapper .prev.disabled, .pagination-wrapper .next.disabled {
  cursor: not-allowed;
  color: $light-grey;
}

.ml-2 {
  margin-left: 0.5rem;
}
